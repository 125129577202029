<template>
  <v-card>
    <v-card-title>Appointments</v-card-title>
    <v-card-text>
      <v-fade-transition>
        <v-alert
          v-show="actionAlert"
          border="left"
          color="success"
          dark
          text
          dismissible
        >
          {{ actionAlertMessage }}
        </v-alert>
      </v-fade-transition>
    </v-card-text>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-model="searchQuery"
        placeholder="Search"
        outlined
        hide-details
        dense
        clearable
        class="user-search me-3 mb-4"
        @keyup.enter="fetchAppointments"
        @click:clear="clearSearch"
      >
      </v-text-field>

      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
        <v-btn
          v-if="$store.getters['auth/can'](['create appointments'])"
          color="primary"
          class="mb-4 me-3"
          @click="$router.push({name: 'appointments-create'})"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Appointment</span>
        </v-btn>

        <!-- <v-btn
          color="secondary"
          outlined
          class="mb-4"
          disabled
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiExportVariant }}
          </v-icon>
          <span>Export</span>
        </v-btn> -->
      </div>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="appointments"
        :options.sync="options"
        :server-items-length="totalAppointments"
        :footer-props="footerProps"
        @page-count="pageCount = $event"
      >
        <!-- date -->
        <template #[`item.date`]="{ item }">
          <v-avatar
            size="30"
            color="success"
            class="v-avatar-light-bg success--text me-3"
          >
            <v-icon
              size="18"
              color="success"
            >
              {{ icons.mdiCalendarBlankOutline }}
            </v-icon>
          </v-avatar>
          <span class="font-weight-semibold text-truncate text--primary">{{ resolveDate(item.booked_date) }}, {{ item.booked_time }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            :class="`v-chip-light-bg ${resolveAppointmentStatus(item.status)}--text`"
            :color="resolveAppointmentStatus(item.status)"
            small
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="$store.getters['auth/can'](['edit appointments'])"
                @click="$router.push({ name: 'appointments-edit', params: { id: item.id } })"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="$store.getters['auth/can'](['delete appointments'])"
                @click="deleteItemConfirm(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
    <!-- dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="dialogDelete = !dialogDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiExportVariant,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiCalendarBlankOutline,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import useAppointments from './useAppointments'
import useUIResolver from '../useUIResolver'
import store from '@/store'

const defaultAlertMessage = 'Appointment successfully saved.'

export default {
  setup() {
    const appointmentData = ref({})
    const actionAlert = ref(false)
    const actionAlertMessage = ref('')
    const appointmentsEditorDialog = ref(false)
    const searchQuery = ref('')
    const totalAppointments = ref(0)
    const footerProps = ref({
      itemsPerPageOptions: [15, 50, 100],
    })
    const options = ref({
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 15,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ['name'],
      sortDesc: [],
    })
    const dialogDelete = ref(false)

    onMounted(() => {
      actionAlertMessage.value = defaultAlertMessage
    })

    const { appointments, getAppointments } = useAppointments()
    const { appointmentTableHeaders, resolveDate, resolveAppointmentStatus } = useUIResolver()

    const fetchAppointments = () => {
      getAppointments({ ...options.value, ...{ searchQuery: searchQuery.value } }).then(response => {
        const { total } = response.data
        totalAppointments.value = total
        options.value.itemsPerPage = response.data.per_page
      })
    }

    const clearSearch = () => {
      searchQuery.value = null
      fetchAppointments()
    }

    const saveAppointment = () => {
      actionAlertMessage.value = defaultAlertMessage
      actionAlert.value = true
      fetchAppointments()
    }

    const deleteItem = () => {
      axios.delete(`/appointments/${appointmentData.value.id}`).then(response => {
        actionAlertMessage.value = 'Appointment successfully deleted.'
        actionAlert.value = true

        // hide confirm alert
        dialogDelete.value = false
        fetchAppointments()
        store.dispatch('app/getPendingAppointmentsCount')
      })
    }

    const deleteItemConfirm = item => {
      appointmentData.value = item
      dialogDelete.value = true
    }

    watch(options, fetchAppointments)

    watch(actionAlert, val => {
      if (val) {
        setTimeout(() => {
          actionAlert.value = false
        }, 3000)
      }
    })

    return {
      dialogDelete,
      actionAlert,
      actionAlertMessage,
      appointmentData,
      headers: appointmentTableHeaders(),
      searchQuery,
      totalAppointments,
      footerProps,
      options,

      // useUIResolver
      resolveDate,
      resolveAppointmentStatus,

      // useAppoint@ ments
      appointments,
      getAppointments,

      // functions
      clearSearch,
      appointmentsEditorDialog,
      saveAppointment,
      fetchAppointments,

      deleteItem,
      deleteItemConfirm,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiExportVariant,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiCalendarBlankOutline,
      },
    }
  },
}
</script>
