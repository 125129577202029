import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function useAppointments() {
  const appointments = ref([])
  const loading = ref(false)
  const searchAppointment = ref(null)
  const appoinment = ref({})

  const getAppointments = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/appointments/filter', params)
        .then(response => {
          const { data } = response.data
          appointments.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getAppointment = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/appointments/${id}`)
        .then(response => {
          const { data } = response
          appoinment.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // vars
    loading,
    searchAppointment,
    appointments,
    appoinment,

    // functions
    getAppointments,
    getAppointment,
  }
}
